<template>
  <section class="pre-footer-section">
    <div class="pre-footer-banner">
      <div class="nom-banner-title billy-font text-center mb-4">
        News-O-Matic is perfect for readers of all levels and interests
      </div>
      <div class="d-flex justify-content-center mb-4">
        <a
          href="https://owlkids.secure.darwin.cx/M3KNEWSN"
          class="nom-btn"
          target="_blank"
        >
          <img src="/img/bell-btn.svg" height="30" class="me-2" />
          Subscribe
        </a>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/nom-components.scss";

.pre-footer-section {
  background-color: $light-beige;
  padding-top: 2rem;
}

.pre-footer-banner {
  background-color: $beige;
  padding-top: 4rem;
  padding-bottom: 4rem;
  clip-path: ellipse(50% 100% at 50% 100%);
  text-align: center;
}

.nom-banner-title {
  font-family: $font-billy;
  max-width: 800px;
  margin: auto;
  padding: 1rem 6rem;
}
.nom-btn {
  font-family: $font-billy !important;
  color: white !important;
}

@include tablet-size {
  .pre-footer-section {
    padding-top: 0rem;
  }
  .pre-footer-banner {
    padding-top: 6rem;
    padding-bottom: 2rem;
    clip-path: ellipse(90% 100% at 50% 100%);
    text-align: center;
  }
}

@include phone-size {
  .pre-footer-section {
    padding-top: 0rem;
  }
  .pre-footer-banner {
    padding-top: 6rem;
    padding-bottom: 2rem;
    clip-path: ellipse(90% 100% at 50% 100%);
    text-align: center;
  }

  .nom-banner-title {
    padding: 0 1rem;
  }
}
</style>
