<template>
  <section class="help-section">
    <div class="nom-container">
      <div class="row align-items-center">
        <div class="col-lg-5 mb-3 hoot">
          <img src="/img/Hoot-happy-shrugging.png" class="w-100 hoot-img" />
        </div>
        <div class="col-lg-6 mb-4 text">
          <div class="nom-banner-title mb-4">Need help logging in?</div>
          <div class="nom-banner-subtitle mb-4 text">
            To access News-O-matic as part of your OWL magazine print
            subscription, you must enter the email associated with your Owlkids
            account. If this is not working, or if you do not have an email on
            file, please contact
            <a href="mailto:customerservice@owlkids.com"
              >customerservice@owlkids.com</a
            >
            for assistance.
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/nom-components.scss";

.help-section {
  min-height: 85vh;
  background-color: $beige;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@include tablet-size {
  .help-section {
    padding-top: 3rem;
  }

  .hoot {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .hoot-img {
    width: 600px !important;
    margin: auto;
  }

  .text {
    text-align: center;
  }
}

@include phone-size {
  .nom-banner-title {
    font-size: 2.1rem !important;
  }

  .nom-banner-subtitle {
    font-size: 1.4rem !important;
  }
}
</style>
