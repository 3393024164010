<template>
  <section class="home-banner">
    <div class="nom-container">
      <div class="row justify-content-center">
        <!-- Left Section -->
        <div class="col-md-6 col-lg-4 order-2 order-md-1">
          <img src="/img/hoot-megaphone.png" class="w-100 hoot-img" />
        </div>
        <!-- Right Section -->
        <div class="text-center col-md-6 col-lg-6 order-1 order-md-2">
          <div class="nom-banner-title mb-4">
            INTRODUCING<br />
            <span class="billy-font">NEWS-O-MATIC</span><br />
            A DAILY NEWS <br />PLATFORM FOR KIDS
          </div>
          <div class="nom-banner-subtitle mb-4">
            Created in partnership with Owlkids!
          </div>
          <div>
            <a
              href="https://owlkids.secure.darwin.cx/M3KNEWSN"
              class="nom-btn"
              target="_blank"
            >
              <img src="/img/bell-btn.svg" height="30" class="me-2" />
              Subscribe
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/nom-components.scss";
.home-banner {
  background-color: $beige;
  padding-top: 4rem;
  padding-bottom: 2rem;
}
.billy-font {
  font-family: $font-billy;
}
.nom-btn {
  display: inline-block;
  font-family: $font-billy !important;
  color: white !important;
}
.hoot-img {
  margin-bottom: -120px;
}
</style>
