<script>

</script>

<template>
  <footer class="footer">
    <div class="nom-container">
      <div class="row align-items-center justify-content-md-between">
        <div class="col-md-4 mb-3 mb-md-0">
          <div>© 2023 by News-O-Matic</div>
          <div>© 2023 by OwlKids</div>
        </div>

        <div class="col-md-3 mb-3 mb-md-0">
          <router-link
            to="/help"
            class="footer-btn"
          >
            <i class="fa-sharp fa-solid fa-circle-info"></i>
            Need Help?
          </router-link>
        </div>

        <div class="col-md-3 mb-md-0">
          <div>customerservice@owlkids.com</div>
          <div class="mb-2">Tel: 1-877-954-2700</div>

          <a
            href="https://www.owlkids.com/privacy-policy"
            class="text-white"
            target="_blank"
            >Privacy Policy</a
          >
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/nom-components.scss";

.footer {
  padding: 30px;
  background-color: $red;
  color: white;
}

a {
  text-decoration: none;
}

.footer-btn {
  font-family: $font-billy !important;
  font-size: 1.3rem;
  background-color: white;
  color: $red;
  border: 0;
  border-radius: calc(2rem + 1.2rem);
  padding: 1rem 2rem;
  text-decoration: none;

  &:hover {
    transform: scale(1.1);
    transition: 0.1s;
  }
}
.help-btn {
  background-color: white;
  color: $red;
  border: solid 3px $red;
  border-radius: 50%;
  padding: 0.45rem 0.8rem;
}

@include tablet-size {
  .footer {
    font-size: 0.9rem;
    padding: 20px;
  }
  .footer-btn {
    font-size: 1rem;
    padding: 0.8rem 1rem;
  }
}

@include phone-size {
  .footer-btn {
    font-size: 1rem;
    padding: 0.8rem 0.8rem;
  }
}
</style>
