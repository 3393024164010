<template>
  <section class="benefits-section">
    <div class="benefits-container">
      <div class="nom-section-title text-center mb-5">
        A NEW WAY TO GET YOUR <br />
        NEWS FROM OWLKIDS!
      </div>
      <div class="benefits-icons d-flex flex-wrap justify-content-center">
        <div class="benefit-item">
          <img :src="`/img/benefits-1.svg`" />
          <div>Never the same content, with 5 new stories published daily.</div>
        </div>
        <div class="benefit-item">
          <img :src="`/img/benefits-2.svg`" />
          <div>
            Intriguing stories from Canada and beyond to ignite curiosity and a
            love for reading
          </div>
        </div>
        <div class="benefit-item">
          <img :src="`/img/benefits-3.svg`" />
          <div>Access photos, videos, infographics, and voice recordings</div>
        </div>
        <div class="benefit-item">
          <img :src="`/img/benefits-4.svg`" />
          <div>Content tailored to three reading levels</div>
        </div>
        <div class="benefit-item">
          <img :src="`/img/benefits-5.svg`" />
          <div>Reading comprehension questions following each article</div>
        </div>
        <div class="benefit-item">
          <img :src="`/img/benefits-6.svg`" />
          <div>
            Foster informed readers with critical and independent thinking
            skills
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/nom-components.scss";
.benefits-section {
  background-color: $light-beige;
  padding-top: 6rem;
  padding-bottom: 10rem;
  .benefits-container {
    max-width: 1200px;
    margin: auto;
    padding: 0 2rem;
  }
  .benefits-icons {
    gap: 4rem;
    .benefit-item {
      text-align: center;
      width: auto;
      img {
        width: 100px;
        height: 100px;
        margin-bottom: 1rem;
      }
      div {
        margin-top: 1rem;
        max-width: 250px;
      }
    }
  }
}

@include phone-size {
  .benefits-section {
    padding-top: 8rem;
  }
}
</style>
