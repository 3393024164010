<script setup lang="ts"></script>

<template>
  <div class="container p-4" style="max-width: 800px">
    
    <h1 class="mb-5">
      Components
    </h1>

     <!-- Banner -->
     <div class="mb-5">
      <h2>Banners</h2>
      <div class="example-box">
        <div class="nom-banner-title">.nom-banner-title</div>
      </div>
      <div class="example-box">
        <div class="nom-banner-subtitle">.nom-banner-subtitle</div>
      </div>
    </div>

    <!-- Section -->
    <div class="mb-5">
      <h2>Sections</h2>
      <div class="example-box">
        <div class="nom-section-title">.nom-section-title</div>
      </div>
      <div class="example-box">
        <div class="nom-section-subtitle">.nom-section-subtitle</div>
      </div>
      <div class="example-box">
        <div class="nom-section-subtitle orange">.nom-section-subtitle .orange</div>
      </div>
      <div class="example-box">
        <div class="">
          In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
        </div>
      </div>
      <div class="example-box">
        <a href="#">Follow this hyperlink</a>
      </div>
    </div>

    <!-- Buttons -->
    <div class="mb-5">
      <h2>Buttons</h2>
      <div class="example-box">
        <button class="nom-btn">.nom-btn</button>
      </div>
      <div class="example-box">
        <button class="nom-btn outline">.nom-btn .outline</button>
      </div>
      <div class="example-box">
        <button class="nom-btn me-2" disabled>disabled</button>
        <button class="nom-btn outline" disabled>disabled</button>
      </div>
    </div>

  </div>
</template>
<style lang="scss" scoped>
.example-box {
  padding: 0.5em;
  border: solid 1px #ddd;
  margin-bottom: 0.5rem;
}
code {
  width: 100%;
  font-family: Consolas, 'courier new';
  color: rgb(16, 142, 138);
  background-color: #f1f1f1;
  display: inline-block;
  font-size: 1em;
  border: solid 1px #ddd;
  padding: 0.5em;
  border-radius: 0.2em;
}
</style>