<template>
  <section class="team-section">
    <div class="nom-container">
      <div class="nom-section-title text-center mb-5">
        MEET OUR <span class="billy-font">NEWS-O-MATIC</span> CANADA TEAM
      </div>
      <div class="team-icons mb-5">
        <div class="team-item">
          <img :src="`/img/Russ.png`" />
          <div class="nom-section-subtitle">Russ Kahn</div>
          <div class="nom-section-subtitle orange mb-1">
            Chief Content Officer
          </div>
          <div>
            Russell Kahn is the Chief Content and Education Officer for
            News-O-Matic. Kahn has authored a dozen educational books covering
            career readiness, nonfiction ELA, the Praxis exams, and the Common
            Core State Standards.
          </div>
        </div>
        <div class="team-item">
          <img :src="`/img/Melissa.png`" />
          <div class="nom-section-subtitle">Melissa Owens</div>
          <div class="nom-section-subtitle orange mb-1">Editor</div>
          <div>
            Melissa is the editor of OWL magazine and News-O-Matic Canada. It is
            her passion to provide quality content to youth audiences that
            inspires, educates, and entertains!
          </div>
        </div>
        <div class="team-item">
          <img :src="`/img/John.png`" />
          <div class="nom-section-subtitle">John Crossingham</div>
          <div class="nom-section-subtitle orange mb-1">Senior Writer</div>
          <div>
            John is the Senior writer for Owlkids and News-o-Matic Canada. A
            graduate of Brock University, where he majored in English, John has
            worked in children’s publishing for over 25 years.
          </div>
        </div>
        <div class="team-item">
          <img :src="`/img/Mandy.png`" />
          <div class="nom-section-subtitle">Mandy Ng</div>
          <div class="nom-section-subtitle orange mb-1">Editor</div>
          <div>
            Mandy is the editor of Chickadee magazine and a contributing writer
            and editor for News-O-Matic Canada. Mandy especially loves writing
            about nature and finding fun facts about animals.
          </div>
        </div>
        <div class="team-item">
          <img :src="`/img/Rebecca.png`" />
          <div class="nom-section-subtitle">Rebecca Morrison</div>
          <div class="nom-section-subtitle orange mb-1">
            Contributing Writer
          </div>
          <div>
            Rebecca is a contributing writer for Chirp, Chickadee, and OWL
            magazines and News-o-Matic Canada. When she’s not busy researching
            super cool facts, you can probably find her glued to a really good
            fantasy novel or annoying her two cats, Ollie and Storm.
          </div>
        </div>
        <div class="team-item">
          <img :src="`/img/Vanessa.png`" />
          <div class="nom-section-subtitle">Vanessa Quon</div>
          <div class="nom-section-subtitle orange mb-1">
            Contributing Writer
          </div>
          <div>
            Vanessa is a contributing writer for News-o-Matic Canada. Her
            interests include painting, traveling, practicing yoga, and cozying
            up with a good mystery novel.
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/nom-components.scss";

.team-section {
  background-color: $light-beige;
  padding-top: 6rem;
  padding-bottom: 4rem;
}
.billy-font {
  font-family: $font-billy;
}
.team-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5rem;
}

.team-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 325px;
  img {
    margin-bottom: 1.5rem;
    max-width: 250px;
  }
}

@include phone-size {
  .team-icons {
    gap: 3rem;
  }
}
</style>
