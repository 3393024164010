import { createRouter, createWebHistory } from "vue-router";

import Components from "@/views/Components.vue";
import HomeView from "../views/Home.vue";
import Help from "@/views/Help.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/components",
    name: "components",
    component: Components,
  },
  {
    path: "/help",
    name: "help",
    component: Help,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.path === "/help") {
      return { top: 0 };
    }
    return savedPosition || { top: 0 };
  },
});

export default router;
