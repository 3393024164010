<template>
  <section class="articlesSection">
    <div class="articles-container">
      <div class="nom-section-title text-center mx-4 mb-4">
        GET A PREVIEW OF OUR LATEST ARTICLES
      </div>
    </div>
    <div class="nom-container">
      <div class="articles-icons mb-5">
        <div class="article-item">
          <img :src="`/img/article-1.png`" />
          <div class="nom-section-subtitle">Food Banks Face Challenges</div>
          <div>More people are using Canadian food banks than ever.</div>
        </div>
        <div class="article-item">
          <img :src="`/img/article-2.png`" />
          <div class="nom-section-subtitle">First Nations First</div>
          <div>
            NDP Leader Wab Kinew is the first-ever First Nations premier of a
            Canadian province.
          </div>
        </div>
        <div class="article-item">
          <img :src="`/img/article-3.png`" />
          <div class="nom-section-subtitle">Just Like the Moon</div>
          <div>
            Astronauts are visiting a crater in northern Labrador to learn about
            rocks on the Moon.
          </div>
        </div>
        <div class="article-item">
          <img :src="`/img/article-4.png`" />
          <div class="nom-section-subtitle">
            Celebrating Women’s History Month
          </div>
          <div>Meet Viola Desmond.</div>
        </div>
      </div>
      <div class="d-flex justify-content-center mb-4">
        <a
          href="https://owlkids.secure.darwin.cx/M3KNEWSN"
          class="nom-btn"
          target="_blank"
        >
          <img src="/img/bell-btn.svg" height="30" class="me-2" />
          Subscribe
        </a>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/nom-components.scss";

.articlesSection {
  background-color: $beige;
  padding-bottom: 2rem;
  .articles-container {
    width: 100%;
    text-align: center;
  }
  .nom-section-title {
    background-color: $light-beige;
    border-radius: 2rem;
    padding: 2rem 4rem;
    display: inline-block;
    position: relative;
    top: -50px;
  }
}
.articles-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem;
  .article-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 550px;
    img {
      border-radius: 3rem;
      margin-bottom: 1rem;
    }
  }
}
.nom-btn {
  font-family: $font-billy !important;
  color: white !important;
}

@include phone-size {
  .articles-icons {
    gap: 3rem;
  }
}
</style>
